/* global lazyload */
import 'lazyload';

import Abstract from './abstract';
import { onSendGTag } from './module';

const lazyloadDescriptionImage = () => {
  const imagesLazyload = document.querySelectorAll('.trill-description .image-block .image-block__image');

  lazyload(imagesLazyload);
};

const onSendReadMoreContinueGTag = () => {
  onSendGTag('タップ', {
    event_category: '続きを読む',
    event_label: 'タップ',
    non_interaction: true,
  });
};

const onSendOriginalLinkGTag = (e) => {
  const link = e.currentTarget.getAttribute('href');

  onSendGTag('タップ', {
    event_category: '元記事を読む',
    event_label: 'タップ',
    link_url: link,
  });
};

const onSendPVBoostLinkGTag = (e) => {
  const link = e.currentTarget.getAttribute('href');

  onSendGTag('タップ', {
    event_category: 'スポコン救済枠',
    event_label: 'タップ',
    link_url: link,
  });
};

const onSendNextArticleLinkGTag = (e) => {
  const link = e.currentTarget.getAttribute('href');

  onSendGTag('タップ', {
    event_category: '次の記事',
    event_label: 'タップ',
    link_url: link,
  });
};

const onSendMediumRelatedArticleLinkGTag = (e) => {
  const title = e.currentTarget.dataset.gaMediumRelatedArticleTitle;
  const link = e.currentTarget.getAttribute('href');

  onSendGTag('タップ', {
    event_category: 'メディア関連記事枠',
    event_label: title,
    link_url: link,
  });
};

const onSendRankingLinkGTag = (e) => {
  const link = e.currentTarget.getAttribute('href');
  const isManagementRanking = e.currentTarget.classList.contains('js-article-detail-management-ranking-link');
  const eventCategory = isManagementRanking ? '管理カテゴリランキング' : 'ランキング';

  onSendGTag('タップ', {
    event_category: eventCategory,
    event_label: 'タップ',
    link_url: link,
  });
};
const onSendRelatedArticleLinkGTag = (e) => {
  const title = e.currentTarget.dataset.gaRelatedArticleTitle;
  const link = e.currentTarget.getAttribute('href');

  onSendGTag('タップ', {
    event_category: 'あなたにおすすめの記事',
    event_label: title,
    link_url: link,
  });
};

const onSendClickArticleImageLinkGTag = (e) => {
  const link = e.currentTarget.getAttribute('href');

  onSendGTag('タップ', {
    event_category: '記事説明画像',
    event_label: 'タップ',
    link_url: link,
  });
};

const onSendClickNextArticleMediumMangaLinkGTag = (e) => {
  const link_url = e.currentTarget.getAttribute('href');

  onSendGTag('タップ', {
    event_category: '次の記事_manga',
    event_label: 'タップ',
    link_url,
  });
};

const onSendClickPreviousArticleMediumMangaLinkGTag = (e) => {
  const link_url = e.currentTarget.getAttribute('href');

  onSendGTag('タップ', {
    event_category: '前の記事_manga',
    event_label: 'タップ',
    link_url,
  });
};

const onSendClickFirstArticleMediumMangaLinkGTag = (e) => {
  const link_url = e.currentTarget.getAttribute('href');

  onSendGTag('タップ', {
    event_category: '一話の記事_manga',
    event_label: 'タップ',
    link_url,
  });
};

const onSendClickMangaCreatorDetailLinkGTag = (e) => {
  const link_url = e.currentTarget.getAttribute('href');

  onSendGTag('タップ', {
    event_category: '作品をもっと見る',
    event_label: 'タップ',
    link_url,
  });
};

const onSendOptimizeViewArticlePhotoPatternGTag = (pattern) => {
  onSendGTag('optimize_view', {
    optimize_category: 'article_photo',
    optimize_label: 'view',
    optimize_pattern: pattern,
  });
};

const onSendOptimizeClickArticlePhotoPatternGTag = (pattern) => {
  onSendGTag('optimize_click', {
    optimize_category: 'article_photo',
    optimize_label: 'click',
    optimize_pattern: pattern,
  });
};

export default class ArticleDetailView extends Abstract {
  init() {
    this.getElement();

    this.addEvent();

    this.addGAM();
  }

  getElement() {
    this.headerOverlay = document.querySelector('.js-header-overlay-ads');

    this.descriptionOuter = document.querySelector('.js-article-description-outer');
    this.readMoreOuter = document.querySelector('.js-article-readmore-outer');
    this.readMoreButton = document.querySelector('.js-article-readmore-button');

    this.pvboostLink = Array.prototype.slice.call(document.querySelectorAll('.js-article-pvboost-link'));

    this.nextArticleLinks = document.querySelectorAll('.js-next-article-link');

    this.originalLink = document.querySelector('.js-article-original-link');
    this.relatedMediumArticleLinks = Array.prototype.slice.call(document.querySelectorAll('.js-medium-related-article-link'));

    this.rankingLinks = document.querySelectorAll('.js-article-detail-ranking-link, .js-article-detail-management-ranking-link');

    this.relatedArticleLinks = Array.prototype.slice.call(document.querySelectorAll('.js-related-article-link'));

    this.ArticleDetailContainer = document.querySelector('.js-article-detail-container');
    this.articleDescriptionContainer = document.querySelector('.js-article-description-outer')
      || document.querySelector('.js-sponsored-article-description-outer');

    this.nextArticleMediumMangaLink = document.querySelector('.js-next-article-medium-manga-link');
    this.previousArticleMediumMangaLink = document.querySelector('.js-previous-article-medium-manga-link');
    this.firstArticleMediumMangaLink = document.querySelector('.js-first-article-medium-manga-link');

    this.mangaCreatorDetailLink = document.querySelector('.js-article-detail-manga-creator-detail-link');
  }

  addEvent() {
    if (this.descriptionOuter && this.readMoreButton) this.readMoreButton.addEventListener('click', this.onClick.bind(this));

    if (this.pvboostLink) {
      this.pvboostLink.forEach((el) => {
        el.addEventListener('click', onSendPVBoostLinkGTag);
      });
    }

    if (this.originalLink) this.originalLink.addEventListener('click', onSendOriginalLinkGTag);

    if (this.nextArticleLinks) {
      this.nextArticleLinks.forEach((element) => {
        element.addEventListener('click', onSendNextArticleLinkGTag);
      });
    }

    if (this.relatedMediumArticleLinks) {
      this.relatedMediumArticleLinks.forEach((el) => {
        el.addEventListener('click', onSendMediumRelatedArticleLinkGTag);
      });
    }

    if (this.rankingLinks) {
      this.rankingLinks.forEach((el, index) => {
        el.addEventListener('click', (e) => {
          const indexNumber = index + 1;
          onSendRankingLinkGTag(e, indexNumber);
        });
      });
    }

    if (this.relatedArticleLinks) {
      this.relatedArticleLinks.forEach((el) => {
        el.addEventListener('click', onSendRelatedArticleLinkGTag);
      });
    }

    const pageviewContent = (window.dataLayer && window.dataLayer[0] && window.dataLayer[0].pageviewContent) || {};

    if (window.isDisplayedArticlePhoto) {
      onSendOptimizeViewArticlePhotoPatternGTag('image_all');
    }

    const isArticleImageGallery = pageviewContent.is_displayed_article_photo;
    if (this.articleDescriptionContainer && isArticleImageGallery) {
      onSendOptimizeViewArticlePhotoPatternGTag('image_link');
      this.articleDescriptionContainer.querySelectorAll('.trill-description img').forEach((imageElement, index) => {
        const newImageLinkElement = document.createElement('a');

        // eslint-disable-next-line no-param-reassign
        imageElement.style.visibility = 'visible';
        newImageLinkElement.href = `/articles/${pageviewContent.article_id}/photos/${index + 1}`;
        newImageLinkElement.className = 'image-block__link-container';
        newImageLinkElement.innerHTML = `
          ${imageElement.outerHTML}
        `;

        imageElement.insertAdjacentElement('beforebegin', newImageLinkElement);
        // eslint-disable-next-line no-param-reassign
        imageElement.style.display = 'none';
        newImageLinkElement.addEventListener('click', (event) => {
          onSendClickArticleImageLinkGTag(event);
          onSendOptimizeClickArticlePhotoPatternGTag('image_link');
        });
      });
      lazyloadDescriptionImage();
    }

    if (this.nextArticleMediumMangaLink) this.nextArticleMediumMangaLink.addEventListener('click', onSendClickNextArticleMediumMangaLinkGTag);
    if (this.previousArticleMediumMangaLink) this.previousArticleMediumMangaLink.addEventListener('click', onSendClickPreviousArticleMediumMangaLinkGTag);
    if (this.firstArticleMediumMangaLink) this.firstArticleMediumMangaLink.addEventListener('click', onSendClickFirstArticleMediumMangaLinkGTag);

    if (this.mangaCreatorDetailLink) this.mangaCreatorDetailLink.addEventListener('click', onSendClickMangaCreatorDetailLinkGTag);
  }

  onClick() {
    this.removeReadMore();

    onSendReadMoreContinueGTag();
  }

  removeReadMore() {
    this.descriptionOuter.classList.remove('articleDetail-MainContent_Body-hidden');

    this.readMoreOuter.remove();

    window.dispatcher.emit('READ_MORE_ARTICLE');
  }

  addGAM() {
    if (this.ArticleDetailContainer) {
      const target2DomH3 = this.ArticleDetailContainer.getElementsByTagName('h3')[1];
      const target4DomH4 = this.ArticleDetailContainer.getElementsByTagName('h4')[3];
      const target4DomH5 = this.ArticleDetailContainer.getElementsByTagName('h5')[3];
      const target3DomH4 = this.ArticleDetailContainer.getElementsByTagName('h4')[2];
      const target10DomP = this.ArticleDetailContainer.getElementsByTagName('p')[9];
      const target5DomFigure = this.ArticleDetailContainer.getElementsByTagName('figure')[4];
      const target7DomP = this.ArticleDetailContainer.getElementsByTagName('p')[6];
      const target5DomP = this.ArticleDetailContainer.getElementsByTagName('p')[4];
      const target4DomFigure = this.ArticleDetailContainer.getElementsByTagName('figure')[3];
      const target3DomP = this.ArticleDetailContainer.getElementsByTagName('p')[2];
      const target2DomP = this.ArticleDetailContainer.getElementsByTagName('p')[1];

      const gam = '<div id="div-gpt-ad-1622988823590-0"></div>';

      if (target2DomH3) {
        target2DomH3.insertAdjacentHTML('beforebegin', gam);
      } else if (target4DomH4) {
        target4DomH4.insertAdjacentHTML('beforebegin', gam);
      } else if (target4DomH5) {
        target4DomH5.insertAdjacentHTML('beforebegin', gam);
      } else if (target3DomH4) {
        target3DomH4.insertAdjacentHTML('beforebegin', gam);
      } else if (target10DomP) {
        target10DomP.insertAdjacentHTML('beforebegin', gam);
      } else if (target5DomFigure) {
        target5DomFigure.insertAdjacentHTML('beforebegin', gam);
      } else if (target7DomP) {
        target7DomP.insertAdjacentHTML('beforebegin', gam);
      } else if (target5DomP) {
        target5DomP.insertAdjacentHTML('beforebegin', gam);
      } else if (target4DomFigure) {
        target4DomFigure.insertAdjacentHTML('beforebegin', gam);
      } else if (target3DomP) {
        target3DomP.insertAdjacentHTML('beforebegin', gam);
      } else if (target2DomP) {
        target2DomP.insertAdjacentHTML('beforebegin', gam);
      }
    }
  }
}
