import Abstract from './abstract';
import { toggleClass } from './module';

const INITIAL_STATE = {
  searchKeyword: '',
};

const DELETE_HIDDEN_CLASS = 'header-SearchForm_Delete-hidden';

export default class SearchView extends Abstract {
  initState() {
    this.state = { ...INITIAL_STATE };
  }

  getElement() {
    this.form = document.querySelector('.js-search-input-form');
    this.input = document.querySelector('.js-search-input');
    this.delete = document.querySelector('.js-search-input-delete');
  }

  addEvent() {
    this.form.addEventListener('submit', this.onSubmit.bind(this));
    this.input.addEventListener('input', this.onInput.bind(this));
    this.delete.addEventListener('click', this.onDeleteClick.bind(this));
  }

  onSubmit(event) {
    if (!this.state.searchKeyword.length) {
      event.preventDefault();
    }
  }

  onInput() {
    this.onUpdateState({
      searchKeyword: this.input.value,
    });

    toggleClass(this.delete, !this.state.searchKeyword.length, DELETE_HIDDEN_CLASS);
  }

  onDeleteClick() {
    this.input.value = '';

    this.onInput();
  }
}
