import Abstract from './abstract';

export default class HeaderAnkerAdView extends Abstract {
  init() {
    this.getElement();
    this.addEvent();
  }

  getElement() {
    this.headerAnkerAd = document.querySelector('.js-header-anker');
    this.ankerAdCloseButton = document.querySelector('.js-header-anker-ad-close-button');
    this.globalHeader = document.querySelector('.js-global-header');
  }

  addEvent() {
    this.ankerAdCloseButton?.addEventListener('click', this.onHide.bind(this));
  }

  onHide() {
    this.headerAnkerAd.style.display = 'none';
    this.globalHeader.classList.remove('header-GlobalHeader-anker');
  }
}
