/* global FB */
import { onSendGTag } from './module';

const onFacebookShare = (shareButton) => {
  const { shareLink } = shareButton.dataset;

  FB.ui({
    method: 'share',
    href: shareLink,
  }, function(response){}); // eslint-disable-line
};

const onSendShareLinkGTag = (e) => {
  const target = e.currentTarget;
  const isFacebookShareButton = target.classList.contains('js-facebook-share-button');
  const { gaCategory } = target.dataset;
  const { gaLabel } = target.dataset;

  onSendGTag('タップ', {
    event_category: gaCategory,
    event_label: gaLabel,
  });

  if (isFacebookShareButton) onFacebookShare(target);
};

export default class ShareUtil {
  constructor() {
    this.getElement();
  }

  init() {
    this.addEvent();
  }

  getElement() {
    this.shareButtons = Array.prototype.slice.call(document.querySelectorAll('.js-sns-share-link-button'));
  }

  addEvent() {
    this.shareButtons.forEach((el) => {
      el.addEventListener('click', onSendShareLinkGTag, {
        once: true,
      });
    });
  }
}
