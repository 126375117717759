import Abstract from './abstract';

export default class GameDetailView extends Abstract {
  constructor() {
    super();

    this.handlePostMessageEvent = this.handlePostMessageEvent.bind(this);
  }

  init() {
    this.getElement();

    this.updateStyles();

    this.addEvent();
  }

  getElement() {
    this.gameMainContentSection = document.querySelector('.js-game-main-content');
    this.gamePlayButton = document.querySelector('.js-detail-game-play-button');
    this.gamePlayIframe = document.querySelector('.js-detail-game-play-iframe');
  }

  updateStyles() {
    if (!this.gameMainContentSection) {
      return;
    }

    document.documentElement.style.overflow = 'hidden';
    document.body.style.overflow = 'hidden';
  }

  addEvent() {
    if (this.gamePlayIframe) {
      window.addEventListener('message', this.handlePostMessageEvent);
    }
  }

  handlePostMessageEvent(event) {
    const { data, origin } = event;

    // Logging
    if (origin === 'https://html5.gamedistribution.com') {
      console.log('Post Message', data);
    }

    if (data && data.topic && data.topic.includes('adrequest.instream')) {
      window.removeEventListener('message', this.handlePostMessageEvent);

      if (this.gamePlayButton) {
        this.gamePlayButton.click();
        this.gamePlayButton.remove();
        this.gamePlayButton = null;
      }
    }
  }
}
