import Abstract from './abstract';
import { onSendGTag } from './module';
const getGoogleAnalyticsParams = (element) => {
  const dataset = element.dataset;

  const eventCategory = dataset.gaEventCategory;
  const eventLabel = dataset.gaEventLabel;
  const linkUrl = element.getAttribute('href');

  return {
    event_category: eventCategory,
    event_label: eventLabel,
    link_url: linkUrl,
  };
}

const onSendClickItemGTag = (event) => {
  const params = getGoogleAnalyticsParams(event.currentTarget);

  onSendGTag('タップ', params);
};

// TODO: Replace and Remove send GA events
export default class GoogleAnalyticsManager extends Abstract {
  init() {
    this.getElement();

    this.addEvent();
  }

  getElement() {
    this.googleAnalyticsItems = document.querySelectorAll('.js-google-analytics-item');
  }

  addEvent() {
    this.googleAnalyticsItems.forEach(event => event.addEventListener('click', onSendClickItemGTag));
  }
}
