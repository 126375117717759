import Abstract from './abstract';

export default class HeaderOverlayAdView extends Abstract {
  init() {
    this.getElement();
    this.addEvent();
  }

  getElement() {
    this.header = document.querySelector('.js-global-header');
    this.wrapper = document.querySelector('.js-wrapper');
    this.headerOverlayAd = document.querySelector('.js-header-overlay-ads');
    this.close = document.querySelector('.js-header-overlay-ads-close-button');
  }

  addEvent() {
    this.close?.addEventListener('click', this.onHide.bind(this));
  }

  onHide() {
    if (this.headerOverlayAd.getBoundingClientRect().height > 130) {
      // 広告が静止画で高さ180pxの時
      this.header.classList.remove('header-GlobalHeader--maxExpanded');
      this.wrapper.classList.remove('wrapper--headerOverlay180');

      this.header.classList.remove('header-GlobalHeader--movieExpanded');
      this.wrapper.classList.remove('wrapper--headerOverlay240');
    } else if (this.headerOverlayAd.getBoundingClientRect().height > 100) {
      // 広告が静止画で高さ120pxの時
      this.header.classList.remove('header-GlobalHeader--middleExpanded');
      this.wrapper.classList.remove('wrapper--headerOverlay120');
    } else {
      this.header.classList.remove('header-GlobalHeader--expanded');
    }

    this.headerOverlayAd.style.display = 'none';
  }
}
