import Abstract from './abstract';
import {
  toggleClass, rootElement, getBrowserHeight, onSendGTag,
} from './module';

const INITIAL_STATE = {
  scrollTop: 0,
  bannersIsFixed: false,
  readComplete: false,
  articleIsExpanded: false,
};

const HEADER_FIXED_CLASS = 'header-GlobalHeader-fixed';
const HEADER_RETRACTED_CLASS = 'header-GlobalHeader-retracted';

export default class ScrollManager extends Abstract {
  constructor() {
    super();

    this.contentsAll = document.scrollingElement || document.documentElement || document.querySelector('.js-contents-all');
    this.rootElement = rootElement();
    this.scrollHandler = this._scrollHandler.bind(this);
  }

  init() {
    this.getElement();

    this.fixedThreshold = this.header.offsetTop;
    this.headerHeight = this.header.clientHeight;
    this.retractedThreshold = this.fixedThreshold + this.headerHeight + 60;
    this.addEvent();
  }

  getElement() {
    this.header = document.querySelector('.js-global-header');
    this.wrapper = document.querySelector('.js-wrapper');
    this.footer = document.querySelector('.js-global-footer');
    this.overlayAd = document.querySelector('.js-overlay-ads');
    this.articleDescriptionOuter = document.querySelector('.js-article-description-outer');
    this.sponsoredArticleDescriptionOuter = document.querySelector('.js-sponsored-article-description-outer');
    this.headerOverlay = document.querySelector('.js-header-overlay-ads');
  }

  addEvent() {
    this.addScrollEvent();

    window.dispatcher.on('OPEN_MODAL', this.removeScrollEvent.bind(this));

    window.dispatcher.on('CLOSE_MODAL', this.addScrollEvent.bind(this));

    window.dispatcher.on('OPEN_OVERLAY', this.removeScrollEvent.bind(this));

    window.dispatcher.on('CLOSE_OVERLAY', this.addScrollEvent.bind(this));

    window.dispatcher.once('READ_MORE_ARTICLE', () => {
      this.onUpdateState({
        articleIsExpanded: true,
      });

      this.onReadCompleteArticle = this._onReadComplete.bind(this, this.articleDescriptionOuter, this.onSendArticleReadGTag.bind(this));

      window.addEventListener('scroll', this.onReadCompleteArticle);
    });

    if (this.sponsoredArticleDescriptionOuter) {
      this.onReadCompleteSponsoredArticle = this._onReadComplete.bind(this, this.sponsoredArticleDescriptionOuter, this.onSendSponsoredArticleGTag.bind(this));

      window.addEventListener('scroll', this.onReadCompleteSponsoredArticle);
    }
  }

  addScrollEvent() {
    window.addEventListener('scroll', this.scrollHandler);
  }

  removeScrollEvent() {
    window.removeEventListener('scroll', this.scrollHandler);
  }

  initState() {
    this.state = { ...INITIAL_STATE };
  }

  _scrollHandler() {
    const { scrollTop } = this.contentsAll;
    const isDownScroll = scrollTop > this.state.scrollTop;
    const isPassedFixedThreshold = scrollTop > this.fixedThreshold;
    const isPassedRetractedThreshold = scrollTop > this.retractedThreshold;

    if (isPassedRetractedThreshold) toggleClass(this.header, isDownScroll, HEADER_RETRACTED_CLASS);
    else this.header.classList.remove(HEADER_RETRACTED_CLASS);

    this.onUpdateState({
      scrollTop,
    });

    if (!this.state.bannersIsFixed && isPassedFixedThreshold) {
      this.onChangeState();
    }

    if (this.state.bannersIsFixed && !isPassedFixedThreshold) {
      this.header.style.position = '';
      this.wrapper.style.paddingTop = '';

      this.onChangeState();
    }
  }

  onChangeState() {
    const changeState = !this.state.bannersIsFixed;

    this.onUpdateState({
      bannersIsFixed: changeState,
    });

    toggleClass(this.header, this.state.bannersIsFixed, HEADER_FIXED_CLASS);

    if (this.overlayAd) {
      this.footer.style.paddingBottom = this.state.bannersIsFixed ? `${this.overlayAd.clientHeight + 20}px` : '';
    }
  }

  _onReadComplete(container, callback) {
    const thresholdElement = container;
    const thresholdPosition = thresholdElement.offsetTop + thresholdElement.clientHeight;

    if (!this.state.readComplete && this.state.scrollTop > thresholdPosition - getBrowserHeight()) {
      this.onUpdateState({
        readComplete: true,
      });

      callback();
    }
  }

  onSendSponsoredArticleGTag() {
    onSendGTag('完読', {
      event_category: 'スポコン完読',
      event_label: '完読',
      non_interaction: true,
    });

    window.removeEventListener('scroll', this.onReadCompleteSponsoredArticle);
  }

  onSendArticleReadGTag() {
    onSendGTag('完読', {
      event_category: '通常記事完読',
      event_label: '完読',
      non_interaction: true,
    });
  }
}
