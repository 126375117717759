/* global lazyload */
import 'lazyload';

import Abstract from './abstract';
import { dateFormat } from './module';

const lazyloadImage = () => {
  const imagesLazyload = document.querySelectorAll('.js-lazyload-image');

  lazyload(imagesLazyload);
};

const INITIAL_STATE = {
  isRequested: false,
};

export default class LoadMoreView extends Abstract {
  initState() {
    this.state = { ...INITIAL_STATE };
  }

  getElement() {
    this.loadMoreButtons = document.querySelectorAll('.js-load-more-button');
    this.loadMoreContainers = document.querySelectorAll('.js-load-more-container');
  }

  // eslint-disable-next-line class-methods-use-this
  getItemsHTML(type, items) {
    let html = '';
    let baseClassName = '';

    const thumbnailHTML = (item, option) => `
      <div
        class="${option?.className} js-lazyload-image"
        data-src="${item?.thumbnail?.image?.url}"
      ></div>
    `;

    const textHTML = (item, option) => `
      <div class="${option?.className}">${item?.[option.key]}</div>
    `;

    const articleAuthorHTML = (item, option) => `
      <p class="${option.baseClassName}_Author">
        ${item.sponsor ? `PR &verbar; ${item.sponsor.name}` : `${(item.medium_item || item.mediumItem)?.medium?.name}`}
      </p>
    `;

    const articleAdditionalInfoHTML = (item, option) => `
      <div class="${option.baseClassName}_AdditionalInfo">
        ${articleAuthorHTML(item, option)}
        ${item.publish_datetime ? `
          <p class="${option.baseClassName}_PublishDatetime">${dateFormat(item.publish_datetime)}</p>
        ` : ''}
      </div>
    `;

    switch (type) {
      case 'commonTop':
        baseClassName = 'commonTop-Articles';
        html = items.map((item) => {
          if (item.gam_tag_id) {
            return `
              <li class="${baseClassName}_Item js-article-list-gam" id="${item.gam_tag_id.id}">
                <script>
                  googletag.cmd.push(function() {
                    googletag.display("${item.gam_tag_id.id}");
                  });
                </script>
              </li>
            `;
          }

          return `
            <li class="${baseClassName}_Item">
              <a class="${baseClassName}_Link" href="articles/${item.id}">
                ${thumbnailHTML(item, { className: `${baseClassName}_Thumbnail` })}
                <div class="${baseClassName}_Information">
                  ${textHTML(item, { className: `${baseClassName}_Title`, key: 'title' })}
                  ${articleAdditionalInfoHTML(item, { baseClassName })}
                </div>
              </a>
            </li>
          `;
        }).join('');
        break;

      case 'managasCreator':
        baseClassName = 'mangas-creators-List_Item';
        html = items.map((item) => `
          <li class="${baseClassName}">
            <a class="${baseClassName}_Link" href="manga/creators/${item.id}">
              ${thumbnailHTML(item, { className: `${baseClassName}_Thumbnail` })}
              <div class="${baseClassName}_Detail">
                ${textHTML(item, { className: `${baseClassName}_Name`, key: 'name' })}
                ${textHTML(item, { className: `${baseClassName}_Introduction`, key: 'introduction' })}
              </div>
            </a>
          </li>
        `).join('');
        break;

      case 'mangasCreatorArticle':
        baseClassName = 'mangas-creator-Articles';
        html = items.map((item) => `
          <li class="${baseClassName}_Item">
            <a class="${baseClassName}_Link" href="articles/${item.id}">
              ${thumbnailHTML(item, { className: `${baseClassName}_Thumbnail` })}
              <div class="${baseClassName}_Information">
                ${textHTML(item, { className: `${baseClassName}_Title`, key: 'title' })}
                ${articleAdditionalInfoHTML(item, { baseClassName })}
              </div>
            </a>
          </li>
        `).join('');
        break;

      case 'mangasSeries':
        baseClassName = 'mangas-seriesList-List_Item';
        html = items.map((item) => `
          <div class="${baseClassName}">
            <a class="${baseClassName}_Link" href="/manga/series/${item.id}">
              ${thumbnailHTML(item, { className: `${baseClassName}_Thumbnail` })}
              ${textHTML(item, { className: `${baseClassName}_Title`, key: 'title' })}
            </a>
          </div>
        `).join('');
        break;

      case 'mangasSeriesArticle':
        baseClassName = 'mangas-series-Articles';
        html = items.map((item) => `
          <li class="${baseClassName}_Item">
            <a class="${baseClassName}_Link" href="articles/${item.id}">
              ${thumbnailHTML(item, { className: `${baseClassName}_Thumbnail` })}
              <div class="${baseClassName}_Information">
                ${textHTML(item, { className: `${baseClassName}_Title`, key: 'title' })}
                ${articleAdditionalInfoHTML(item, { baseClassName })}
              </div>
            </a>
          </li>
        `).join('');
        break;

      case 'mediumArticles':
        baseClassName = 'commonTop-Articles';
        html = items.map((item) => `
          <li class="${baseClassName}_Item">
            <a class="${baseClassName}_Link" href="articles/${item.id}">
              ${thumbnailHTML(item, { className: `${baseClassName}_Thumbnail` })}
              <div class="${baseClassName}_Information">
                ${textHTML(item, { className: `${baseClassName}_Title-large`, key: 'title' })}
                ${articleAdditionalInfoHTML(item, { baseClassName })}
              </div>
            </a>
          </li>
        `).join('');
        break;

      case 'matome':
        baseClassName = 'commonTop-Matomes';
        html = items.map((item) => `
          <div class="${baseClassName}_Item">
            <dt class="${baseClassName}_Header">${item.title}</dt>
            <dd class="${baseClassName}_Detail">
              <ul class="commonTop-Articles">
                ${item.articles?.map((article) => `
                  <li class="commonTop-Articles_Item">
                    <a class="commonTop-Articles_Link" href="articles/${article.id}">
                      ${thumbnailHTML(article, { className: 'commonTop-Articles_Thumbnail' })}
                      <div class="commonTop-Articles_Information">
                        ${textHTML(article, { className: 'commonTop-Articles_Title', key: 'title' })}
                        ${articleAdditionalInfoHTML(article, { baseClassName: 'commonTop-Articles' })}
                      </div>
                    </a>
                  </li>
                `).join('')}
              </ul>
              <a class="${baseClassName}_MoreButton navigate-button" href="matomes/${item.id}">
                この特集をもっと見る
              </a>
            </dd>
          </div>
        `).join('');
        break;

      case 'matomeArticle':
        baseClassName = 'matomes-Articles';
        html = items.map((item) => `
          <li class="${baseClassName}_Item">
            <a class="${baseClassName}_Link" href="articles/${item.id}">
              ${thumbnailHTML(item, { className: `${baseClassName}_Thumbnail` })}
              <div class="${baseClassName}_Information">
                ${textHTML(item, { className: `${baseClassName}_Title`, key: 'title' })}
                ${articleAdditionalInfoHTML(item, { baseClassName })}
              </div>
            </a>
          </li>
        `).join('');
        break;

      case 'personalityQuiz':
        baseClassName = 'commonTop-PersonalityQuizzes';
        html = items.map((item) => `
          <li class="${baseClassName}_Item">
            <a class="${baseClassName}_Link" href="personality-quizzes/${item.id}">
              ${thumbnailHTML(item, { className: `${baseClassName}_Thumbnail` })}
              ${textHTML(item, { className: `${baseClassName}_Title`, key: 'title' })}
            </a>
          </li>
        `).join('');
        break;

      case 'searchedArticle':
        baseClassName = 'search-ResultArticles';
        html = items.map((item) => `
          <li class="${baseClassName}_Item">
            <a class="${baseClassName}_Link" href="articles/${item.id}">
              ${thumbnailHTML(item, { className: `${baseClassName}_Thumbnail` })}
              <div class="${baseClassName}_Information">
                ${textHTML(item, { className: `${baseClassName}_Title`, key: 'title' })}
                ${articleAdditionalInfoHTML(item, { baseClassName })}
              </div>
            </a>
          </li>
        `).join('');
        break;

      case 'popularGame':
        baseClassName = 'games-PopularGamesContainer_Content_List_Item';
        html = items.map((item) => `
          <div class="${baseClassName}">
            <a class="${baseClassName}_Game js-google-analytics-item"
              href="games/${item.id}" data-ga-event-category="無料の人気ゲーム" data-ga-event-label="${item.title}"
            >
              ${thumbnailHTML(item, { className: `${baseClassName}_Game_Thumbnail` })}
              ${textHTML(item, { className: `${baseClassName}_Game_Title`, key: 'title' })}
            </a>
            <div class="${baseClassName}_Company">
              ${textHTML(item, { className: `${baseClassName}_Company_Name`, key: 'display_information' })}
            </div>
          </div>
        `).join('');
        break;

      case 'recommendedGame':
        baseClassName = 'games-RecommendedGamesContainer_Content_List_Item';
        html = items.map((item) => `
          <li class="${baseClassName}">
            <a
              class="${baseClassName}_Link js-google-analytics-item"
              href="games/${item.id}" data-ga-event-category="あなたにおすすめのゲーム" data-ga-event-label="${item.title}"
            >
              ${thumbnailHTML(item, { className: `${baseClassName}_Thumbnail` })}
              <div class="${baseClassName}_Information">
                ${textHTML(item, { className: `${baseClassName}_Title`, key: 'title' })}
                ${textHTML(item, { className: `${baseClassName}_Company`, key: 'display_information' })}
              </div>
            </a>
          </li>
        `).join('');
        break;

      default:
        break;
    }

    return html;
  }

  addEvent() {
    this.loadMoreButtons.forEach((element, index) => element.addEventListener('click', (event) => {
      event.preventDefault();
      this.handleLoadMoreButtonClick(element, this.loadMoreContainers[index]);
    }));
  }

  handleLoadMoreButtonClick(button, container) {
    if (this.state.isRequested) return;

    this.onUpdateState({
      isRequested: true,
    });

    const {
      templateType, location, nextCursor, nextCursorOrder, nextCursorPublishDatetime, nextCursorCreatedAt,
    } = button.dataset;

    // The game's data will be retrieved from the game list page
    const url = window.location.href.replace(/games\/\d+/g, 'games');
    const requestBody = {
      location,
      start_cursor: nextCursor,
      start_cursor_order: nextCursorOrder,
      start_cursor_publish_datetime: nextCursorPublishDatetime,
      start_cursor_created_at: nextCursorCreatedAt,
      gam_count: Array.prototype.slice.call(document.querySelectorAll('.js-article-list-gam')).length,
    };

    fetch(
      `${url}?${new URLSearchParams(requestBody)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const contentType = response.headers.get('Content-Type');
        if (!contentType || !contentType.includes('application/json')) {
          throw new Error(`The response is not JSON. Content-Type: ${contentType}`);
        }

        return response.json();
      })
      .then((data) => {
        const {
          items,
          next_cursor: newNextCursor,
          next_cursor_order: newNextCursorOrder,
          next_cursor_publish_datetime: newCursorPublishDatetime,
          next_cursor_created_at: newNextCursorCreatedAt,
        } = data;
        const itemsHTML = this.getItemsHTML(templateType, items);

        if (container) {
          container.insertAdjacentHTML('beforeend', itemsHTML);
          lazyloadImage();
        }

        if (newNextCursor) {
          button.setAttribute('data-next-cursor', newNextCursor);
        }

        if (newNextCursorOrder) {
          button.setAttribute('data-next-cursor-order', newNextCursorOrder);
        }

        if (newCursorPublishDatetime) {
          button.setAttribute('data-next-cursor-publish-datetime', newCursorPublishDatetime);
        }

        if (newNextCursorCreatedAt) {
          button.setAttribute('data-next-cursor-created-at', newNextCursorCreatedAt);
        }

        if (!(newNextCursor || newNextCursorOrder || newCursorPublishDatetime || newNextCursorCreatedAt)) {
          button.remove();
        }

        this.onUpdateState({
          isRequested: false,
        });
      })
      .catch((error) => {
        console.error('An error occurred:', error);
      });
  }
}
