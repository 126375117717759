/* global Swiper */
import Abstract from './abstract';

const MENU_BAR_VISIBLE_CLASS = 'header-MenuBar-visible';
const MENU_BAR_LINK_CURRENT_CLASS = 'header-MenuBar_Link-current';
const RECOMMENDED_ARTICLES_VISIBLE_CLASS = 'commonTop-RecommendedArticles-visible';

const swiperInit = (parentEl, options) => {
  const container = parentEl.querySelector('.swiper');

  return new Swiper(container, options);
};

const INITIAL_STATE = {
  currentMenuIndex: 0,
};

export default class CarouselView extends Abstract {
  constructor() {
    super();

    this.currentPage = location.pathname;
  }

  init() {
    this.getElement();

    if (this.menuBar) {
      this.checkCurrentMenuIndex();

      // eslint-disable-next-line no-unused-vars
      this.menuBarSwiper = swiperInit(this.menuBar, {
        slidesPerView: 'auto',
        loop: true,
        loopAdditionalSlides: 5,
        centeredSlides: true,
        initialSlide: this.state.currentMenuIndex,
        on: {
          init: () => {
            this.menuBar.classList.add(MENU_BAR_VISIBLE_CLASS);

            window.dispatcher.emit('MENU_BAR_INIT');
          },
        },
      });
    }

    if (this.recommendedArticles) {
      // eslint-disable-next-line no-unused-vars
      this.recommendedArticlesSwiper = swiperInit(this.recommendedArticles, {
        slidesPerView: 'auto',
        spaceBetween: 20,
        centeredSlides: true,
        loop: true,
        pagination: {
          el: '.js-recommended-articles-swiper-pagination',
          clickable: true,
        },
        on: {
          init: () => {
            this.recommendedArticles.classList.add(RECOMMENDED_ARTICLES_VISIBLE_CLASS);
          },
        },
      });
    }
  }

  initState() {
    this.state = { ...INITIAL_STATE };
  }

  getElement() {
    this.menuBar = document.querySelector('.js-menu-bar');
    this.recommendedArticles = document.querySelector('.js-recommended-articles-swiper');
  }

  checkCurrentMenuIndex() {
    this.menuBarLinks = Array.prototype.slice.call(this.menuBar.querySelectorAll('.js-menu-bar-link'));

    this.menuBarLinks.forEach((el, index) => {
      const pattern = el.dataset.linkPattern;
      if (pattern && (new RegExp(pattern)).test(this.currentPage)) {
        this.onUpdateState({
          currentMenuIndex: index,
        });

        el.classList.add(MENU_BAR_LINK_CURRENT_CLASS);
      }
    });
  }
}
