import ScrollManager from './scrollManager';
import OverlayMenuView from './overlayMenuView';
import SearchView from './searchView';
import CarouselView from './carouselView';
import FooterView from './footerView';
import PersonalityQuizView from './personalityQuizView';
import ArticleDetailView from './articleDetailView';
import ShareUtil from './shareUtil';
import LoadMoreView from './loadMoreView';
import LazyloadImage from './lazyloadImage';
import HeaderAnkerAdView from './headerAnkerAdView';
import HeaderOverlayAdView from './headerOverlayAdView';
import ToggleTextView from './toggleTextView';
import ArticlePhotoView from './article/photoView';
import GameDetailView from './gameDetailView';
import GoogleAnalyticsManager from './googleAnalyticsManager';

const EventEmitter = require('events');

const commonInit = () => {
  window.dispatcher = new EventEmitter();

  const scrollManagerInstance = new ScrollManager();
  const overlayMenuInstance = new OverlayMenuView();
  const carouselInstance = new CarouselView();
  const searchInstance = new SearchView();
  const footerInstance = new FooterView();
  const personalityQuizInstance = new PersonalityQuizView();
  const shareUtilInstance = new ShareUtil();
  const headerAnkerAdInstance = new HeaderAnkerAdView();
  const headerOverlayAdInstance = new HeaderOverlayAdView();
  const articleImageView = new ArticlePhotoView();
  const lazyloadImage = new LazyloadImage();
  const gameDetailView = new GameDetailView();
  const googleAnalyticsManager = new GoogleAnalyticsManager();

  scrollManagerInstance.init();
  overlayMenuInstance.init();
  carouselInstance.init();
  searchInstance.init();
  footerInstance.init();
  personalityQuizInstance.init();
  shareUtilInstance.init();
  headerAnkerAdInstance.init();
  headerOverlayAdInstance.init();
  articleImageView.init();
  lazyloadImage.init();
  gameDetailView.init();
  googleAnalyticsManager.init();
};

const articleDetailInit = () => {
  const articleDetailInstance = new ArticleDetailView();

  articleDetailInstance.init();
};

const loadMoreInit = () => {
  const loadMoreInstance = new LoadMoreView();

  loadMoreInstance.init();
};

const loadToggleTextViewInit = () => {
  const toggleTextView = new ToggleTextView();

  toggleTextView.init();
};

const onReady = () => {
  commonInit();

  const isArticlePage = document.querySelector('.js-article');

  if (isArticlePage) articleDetailInit();

  const isLoadMorePage = document.querySelector('.js-load-more-container');

  if (isLoadMorePage) loadMoreInit();

  const isToggleTextPage = document.querySelector('.js-toggle-text-button');
  if (isToggleTextPage) loadToggleTextViewInit();
};

document.addEventListener('DOMContentLoaded', () => {
  onReady();
});
