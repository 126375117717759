import Abstract from './abstract';
import { rootElement, onSendGTag } from './module';

export default class FooterView extends Abstract {
  init() {
    this.getElement();

    this.addEvent();
  }

  getElement() {
    this.rootElement = rootElement();
    this.backToPageTopButton = document.querySelector('.js-back-to-page-top');
    this.officialSNSButtons = Array.prototype.slice.call(document.querySelectorAll('.js-official-sns-link-button'));
  }

  addEvent() {
    this.backToPageTopButton.addEventListener('click', this.onClick.bind(this));

    this.officialSNSButtons.forEach((el) => {
      el.addEventListener('click', (e) => {
        const label = e.currentTarget.dataset.gaLabel;

        onSendGTag('タップ', {
          event_category: 'SNSフォローボタン',
          event_label: label,
        });
      }, {
        once: true,
      });
    });
  }

  onClick() {
    TweenMax.to(this.rootElement, 0.3, {
      scrollTop: 0,
      ease: Power4.easeOut,
    });
  }
}
