import Abstract from './abstract';
import {
  toggleClass, getBrowserHeight, isMobileSafari, rootElement, onSendGTag,
} from './module';

const INITIAL_STATE = {
  isMenuOpen: false,
  isSearchOpen: false,
  scrollTop: 0,
  isFixed: false,
  headerBarPosition: 0,
  headerOverlayPosition: 0,
};

const HEADER_FIXED_CLASS = 'header-GlobalHeader-fixed';
const HEADER_NO_BORDER_CLASS = 'header-HeaderBar-noBorder';
const HAMBURGER_OPEN_CLASS = 'header-Hamburger-open';
const SEARCH_TRIGGER_OPEN_CLASS = 'header-SearchTrigger-open';

const onSendMenuGTag = (e) => {
  const { gaMenuName } = e.currentTarget.dataset;

  onSendGTag('タップ', {
    event_category: 'メニューパネル',
    event_label: gaMenuName,
  });
};

export default class OverlayMenuView extends Abstract {
  constructor() {
    super();

    this.isMobileSafari = isMobileSafari();
    this.rootElement = rootElement();
  }

  init() {
    this.getElement();

    this.headerHeight = this.header.clientHeight;
    this.headerBarHeight = this.headerBar.clientHeight;
    this.headerY = this.header.offsetTop;

    TweenMax.set(this.hamburgerBtnCloseIcon, {
      alpha: 0,
    });

    TweenMax.set(this.searchTriggerCloseIcon, {
      alpha: 0,
    });

    this.addEvent();
  }

  initState() {
    this.state = { ...INITIAL_STATE };
  }

  getElement() {
    this.html = document.querySelector('html');
    this.body = document.querySelector('body');
    this.headerOverlay = document.querySelector('.js-header-overlay-ads');
    this.wrapper = document.querySelector('.js-wrapper');
    this.header = document.querySelector('.js-global-header');
    this.headerBar = this.header.querySelector('.js-headerBar');
    this.hamburgerBtn = this.header.querySelector('.js-hamburger-button');
    this.hamburgerBtnOpenIcon = this.header.querySelector('.js-hamburger-button-open-icon');
    this.hamburgerBtnCloseIcon = this.header.querySelector('.js-hamburger-button-close-icon');
    this.menuList = Array.prototype.slice.call(this.header.querySelectorAll('.js-header-menu-list'));
    this.searchTrigger = this.header.querySelector('.js-search-trigger');
    this.searchTriggerOpenIcon = this.searchTrigger.querySelector('.js-search-trigger-open-icon');
    this.searchTriggerCloseIcon = this.searchTrigger.querySelector('.js-search-trigger-close-icon');
    this.menuContainer = this.header.querySelector('.header-OverlayMenu');
    this.searchFormContainer = this.header.querySelector('.js-overlay-search-form');
    this.searchInputOuter = this.searchFormContainer.querySelector('.js-search-input-outer');
    this.tagList = this.searchFormContainer.querySelector('.js-header-featured-keywords');
    this.menuBar = document.querySelector('.js-menu-bar');
  }

  getPosition() {
    this.onUpdateState({
      headerBarPosition: this.headerBar.getBoundingClientRect(),
      headerOverlayPosition: this.headerOverlay ? this.headerOverlay.getBoundingClientRect() : 0,
    });
  }

  addEvent() {
    this.hamburgerBtn.addEventListener('click', this.onHamburgerClick.bind(this));
    this.searchTrigger.addEventListener('click', this.onSearchTriggerClick.bind(this));

    this.menuList.forEach((el) => {
      el.addEventListener('click', onSendMenuGTag.bind(this));
    });

    window.dispatcher.once('MENU_BAR_INIT', () => {
      this.headerBar.classList.add(HEADER_NO_BORDER_CLASS);
    });
  }

  onHamburgerClick() {
    if (this.state.isSearchOpen) {
      this.toggleSearchForm();

      window.dispatcher.once('TRANSITION_END', () => {
        this.toggleMenu();
      });
    } else {
      this.toggleMenu();
    }
  }

  onSearchTriggerClick() {
    if (this.state.isMenuOpen) {
      this.toggleMenu();

      window.dispatcher.once('TRANSITION_END', () => {
        this.toggleSearchForm();
      });
    } else {
      this.toggleSearchForm();
    }
  }

  toggleMenu() {
    const changeState = !this.state.isMenuOpen;

    this.menuIconCrossFade(changeState);

    this.toggleTransition(this.menuContainer, changeState);

    toggleClass(this.hamburgerBtn, changeState, HAMBURGER_OPEN_CLASS);

    this.onUpdateState({
      isMenuOpen: changeState,
    });

    this.scrollPosFixed(this.state.isMenuOpen);

    this.menuContainer.style.height = `${getBrowserHeight() - this.menuContainer.offsetTop}px`;
  }

  toggleSearchForm() {
    const changeState = !this.state.isSearchOpen;

    this.searchIconCrossFade(changeState);

    this.toggleTransition(this.searchFormContainer, changeState);

    toggleClass(this.searchTrigger, changeState, SEARCH_TRIGGER_OPEN_CLASS);

    this.onUpdateState({
      isSearchOpen: changeState,
    });

    this.scrollPosFixed(this.state.isSearchOpen);

    this.tagList.style.height = `${getBrowserHeight() - this.searchFormContainer.offsetTop - this.searchInputOuter.clientHeight}px`;
  }

  toggleTransition(el, state) {
    this.getPosition();

    if (state) {
      el.style.display = 'block';
      this.header.style.height = `${getBrowserHeight()}px`;

      TweenMax.to(el, 0.2, {
        alpha: 1,
      });
    } else {
      TweenMax.to(el, 0.2, {
        alpha: 0,
        onComplete: () => {
          el.style.display = 'none';
          this.header.style.height = '';

          window.dispatcher.emit('TRANSITION_END');
        },
      });
    }
  }

  menuIconCrossFade(state) {
    const visibleIcon = this[`hamburgerBtn${state ? 'Close' : 'Open'}Icon`];
    const hiddenIcon = this[`hamburgerBtn${state ? 'Open' : 'Close'}Icon`];

    TweenMax.to(visibleIcon, 0.2, {
      alpha: 1,
      delay: 0.15,
      ease: Expo.easeOut,
    });

    TweenMax.to(hiddenIcon, 0.2, {
      alpha: 0,
      ease: Expo.easeOut,
    });
  }

  searchIconCrossFade(state) {
    const visibleIcon = this[`searchTrigger${state ? 'Close' : 'Open'}Icon`];
    const hiddenIcon = this[`searchTrigger${state ? 'Open' : 'Close'}Icon`];

    TweenMax.to(visibleIcon, 0.2, {
      alpha: 1,
      delay: 0.15,
      ease: Expo.easeOut,
    });

    TweenMax.to(hiddenIcon, 0.2, {
      alpha: 0,
      ease: Expo.easeOut,
    });
  }

  scrollPosFixed(isOpen) {
    this.getPosition();
    const headerOverlayHeight = this.state.headerOverlayPosition.height > 100
      ? this.state.headerOverlayPosition.height : (this.state.headerBarPosition.top > 0 ? 100 : 0);

    const stillImgHeaderOverlay = this.state.headerOverlayPosition.height > 100 ? 1 : 0;

    if (isOpen) {
      if (this.header.classList.contains(HEADER_FIXED_CLASS)) {
        this.onUpdateState({
          isFixed: true,
        });

        if (headerOverlayHeight > 0) this.header.style.position = 'fixed';
        this.header.style.position = 'fixed';
      } else {
        this.onUpdateState({
          isFixed: false,
        });

        if (stillImgHeaderOverlay) {
          this.header.style.top = `${headerOverlayHeight}px`;
        } else {
          this.header.style.top = '';
        }

        if (this.menuBar) this.menuBar.style.top = `${this.headerBarHeight + this.headerY}px`;
      }

      this.header.classList.add(HEADER_FIXED_CLASS);

      this.onUpdateState({
        scrollTop: this.rootElement.scrollTop,
      });

      this.tagList.scrollTop = 0;
      this.html.style.overflow = 'hidden';
      this.html.style.marginTop = `-${this.state.scrollTop}px`;
      this.wrapper.style.paddingTop = `${this.headerHeight}px`;
      this.body.style.height = `${getBrowserHeight() + this.state.scrollTop}px`;
      this.body.style.overflow = 'hidden';

      window.dispatcher.emit('OPEN_OVERLAY');
    } else {
      this.html.style.marginTop = '';
      this.html.style.overflow = '';
      this.body.style.height = '';
      this.body.style.overflow = '';
      this.rootElement.scrollTop = this.state.scrollTop;

      if (!this.state.isFixed) {
        this.header.classList.remove(HEADER_FIXED_CLASS);
        this.wrapper.style.paddingTop = '';
        this.header.style.top = '';

        if (this.menuBar) this.menuBar.style.top = '';

        this.onUpdateState({
          isFixed: false,
        });
      }

      window.dispatcher.once('TRANSITION_END', () => {
        window.dispatcher.emit('CLOSE_OVERLAY');
      });
    }
  }
}
